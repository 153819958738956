import gql from "graphql-tag";

export const TRAINING_REQUESTS = gql`
  query filterWithPaginateTrainingRequests($criteria: TrainingRequestFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateTrainingRequests(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
      }
      content {
        id
        detail
        employee {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        training {
          id
          name
          color
        }
        abolisher {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        abolishDateTime
        abolishReason
        requester {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        trainingRequestStatus
        requestDateTime
        trainingPlanned
        trainingRequestProcesses {
          id
          approver {
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          approved
          note
          eventDateTime
          nextApprover
        }
      }
    }
  }
`;
